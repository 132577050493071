<script>
export default {
  name: "SecondProductCard",
  props: {
    Description: String,
    Image1: String,
    Image2: String,
    Ingredients: String,
    Price: String,
    Fake: String,
    Discount: String,
  },
};
</script>
<template>
  <div class="best-holder">
    <div class="best-card">
      <div>
        <div class="photo-div">
          <div class="discount">{{ this.Discount }}%off</div>
          <img :src="Image1" alt="photos" class="photo" />
          <img :src="Image2" alt="" class="beside-photo" />
        </div>
        <div class="desc">
          {{ this.Description }}
        </div>
        <div class="ingredients">
          {{ this.Ingredients }}
        </div>
        <div class="price">
          ₹{{ this.Price }} <span>₹{{ this.Fake }}</span>
        </div>
      </div>
      <div class="btn">
        <button class="card-button">
          <i class="bx bx-cart"></i> Add to Cart
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.best-holder {
  min-height: 300px;
}
.best-card {
  position: relative;
  display: inline-block;
  width: 300px;
  min-height: 100%;
  background-color: #fffefe;
  text-align: left;
  border-radius: 20px;
  box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.737);
  margin: auto;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.photo-div {
  width: 100%;
  height: 250px;
  padding: 15px;
  margin: auto;
  position: relative;
}
.discount {
  position: absolute;
  height: fit-content;
  width: fit-content;
  display: inline-block;
  z-index: 1;
  padding: 5px;
  color: white;
  border-radius: 10px;
  background-color: rgb(255, 196, 0);
  margin-top: 8px;
  margin-left: 5px;
}
.desc {
  margin: 10px;
  font-size: 20px;
  margin-top: 10px;
}
.ingredients {
  margin: 10px;
  color: #a8aaaa;
  font-size: 15px;
}
.price {
  margin-left: 5%;
}
.price span {
  color: red;
  margin-left: 10px;
  text-decoration: line-through;
}
.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.beside-photo:hover {
  opacity: 0;
}
.beside-photo {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition-duration: 600ms;
  border-radius: 20px;
}
.btn {
  width: 80%;
  margin: 10px auto;
  padding: 5px;
  background-color: #2c3030;
  border-radius: 10px;
  color: aliceblue;
  text-align: center;
}
.btn:hover {
  background-color: #525857;
}
</style>
