<template>
  <section class="category">
    <h1>Shop by Categories</h1>
    <div class="cardholder">
      <div class="card" @click="clickHandler">
        <div class="catg_image">
          <img
            src="https://static.vecteezy.com/system/resources/previews/003/519/387/non_2x/almond-design-illustration-vector.jpg"
          />
        </div>
        <div class="catg_title">
          <p>Laddoos</p>
        </div>
      </div>
      <div class="card" @click="clickHandler(450)">
        <div class="catg_image">
          <img
            src="https://us.123rf.com/450wm/seamartini/seamartini2007/seamartini200700373/151334001-dates-dried-fruits-dry-food-snacks-vector-icon-dried-dates-fruity-sweets-diet-nutrition-and-vegetari.jpg?ver=6"
          />
        </div>
        <div class="catg_title">
          <p>Dates</p>
        </div>
      </div>
      <div class="card" @click="clickHandler(900)">
        <div class="catg_image">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUvm8SNKaKZF7-XA7ZyFI1-G2Qxw_JkXgZhQ&usqp=CAU"
          />
        </div>
        <div class="catg_title">
          <p>Super Foodmix</p>
        </div>
      </div>
      <div class="card" @click="clickHandler(900)">
        <div class="catg_image">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUvm8SNKaKZF7-XA7ZyFI1-G2Qxw_JkXgZhQ&usqp=CAU"
          />
        </div>
        <div class="catg_title">
          <p>Keto Products</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "CategoryComponent",
  methods: {
    clickHandler(len) {
      setTimeout(() => {
        this.$router.push("/store");
        window.scrollTo(0, len);
      }, 500);
    },
  },
};
</script>
<style scoped>
.category {
  width: 80vw;
  margin: 10vh auto;
  text-align: center;
}

.card {
  width: 250px;
  height: max-content;
  margin: 3vh;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
.card:hover {
  cursor: pointer;
  transform: scale(1.1);
}
img {
  object-fit: contain;
  width: 100px;
}

h1 {
  font-size: 2rem;
}

.catg_title {
  font-size: 1rem;
}

.cardholder {
  display: grid;
  margin: auto;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  justify-items: center;
  align-items: center;
}
@media screen and (max-width: 750px) {
  .cardholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  img {
    width: 75px;
  }
  .category {
    margin-top: 20rem;
  }
}
</style>
