import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Ocean Of Health",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/store",
    name: "store",
    component: () => import("../views/Store.vue"),
    meta: {
      title: "Product Store",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("../views/Checkout.vue"),
    meta: {
      title: "Checkout",
    },
  },
  {
    path: "/product/:name",
    name: "productView",
    component: () => import("../views/SingleProductPage.vue"),
    meta: {
      title: "Product",
    },
  },
  {
    path: "/recepies",
    name: "recepiesView",
    component: () => import("../views/RecepiesView.vue"),
    meta: {
      title: "Recepies",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, _from, next) => {
  document.title = to.meta.title;
  next();
});
export default router;
