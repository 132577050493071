<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay } from "swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<template>
  <section>
    <div class="left">
      <swiper
        :modules="modules"
        class="mySwiper"
        :loop="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :pagination="{
          clickable: true,
        }"
      >
        <swiper-slide
          ><img src="../../public/images/About-6.jpeg" alt=""
        /></swiper-slide>
        <swiper-slide
          ><img src="../../public/images/About-7.jpeg" alt=""
        /></swiper-slide>
        <swiper-slide
          ><img src="../../public/images/About-3.jpeg" alt=""
        /></swiper-slide>
      </swiper>
      <!-- <div class="img">
        <img src="../../public/images/story-1.jpg" alt="" />
      </div>
      <div class="img">
        <img src="../../public/images/story-2.jpg" alt="" />
      </div>
      <div class="img"><img src="//unsplash.it/500/500" alt="" /></div> -->
    </div>
    <div class="right">
      <div class="heading">
        <p>A little about us</p>
        <h1>Humari Kahani</h1>
      </div>
      <div class="para">
        <p>
          An AIIMS Dietician, A College Professor, 30+ years of marriage, and 1
          son. A story that sounds as good to be true until we discovered the
          problems our son, Gunocean, was unfortunately born with....
        </p>
        <router-link to="/about" @click="scrollTop">Know More</router-link>
      </div>
    </div>
  </section>
</template>
<style scoped>
.swiper {
  display: block;
  position: relative;
  width: 350px;
  height: 350px;
}
.swiper-slide {
  width: 100%;
}
section {
  width: 90vw;
  margin: 2rem auto;
  min-height: max-content;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f2ddcc;
  border-radius: 15px;
}
section .left {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
section .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
/*
section .left .img {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section .left .img:nth-child(1) {
  grid-row: 2/7;
  grid-column: 3/7;
}
section .left .img:nth-child(2) {
  grid-row: 2/7;
  grid-column: 7/11;
}
section .left .img:nth-child(3) {
  grid-row: 7/12;
  grid-column: 5/9;
}
section .left .img img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}*/
section .right {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
/* Heading Styling */
section .right .heading h1 {
  font-size: 3rem;
}
section .right .heading p {
  font-size: 1.2rem;
  text-align: left;
}
section .right .heading {
  margin-bottom: 1.6rem;
}
/* Para Styling */
section .right .para {
  text-align: left;
  padding: 0.5rem;
}
section .right .para p {
  margin-bottom: 2rem;
}
section .right .para ul {
  margin-left: 1rem;
  margin-bottom: 2rem;
}
section a {
  padding: 0.75em 1.5em;
  background-color: orange;
  border-radius: 0.5em;
  text-decoration: none;
  font-size: 1.2em;
  color: white;
}
@media screen and (max-width: 820px) {
  section {
    flex-direction: column;
    height: max-content;
  }
  .swiper {
    width: 300px;
    height: 300px;
  }
  section .left {
    width: 100%;
  }
  section .right {
    margin-top: 4rem;
  }
  section .right .heading p {
    font-size: 1.2rem;
    text-align: left;
  }
}
</style>
