<template>
  <Nav2 />
  <!-- <NavBar /> -->
  <transition name="slide-fade" mode="out in">
    <router-view />
  </transition>
  <Footer />
</template>
<script>
// import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import Nav2 from "./components/Nav2.vue";
export default {
  // eslint-disable-next-line prettier/prettier
  components: { Footer, Nav2 }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  overflow-x: hidden;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
