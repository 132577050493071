<template>
  <link
    href="https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css"
    rel="stylesheet"
  />

  <div class="card-holder">
    <div class="card">
      <div class="card-content">
        <div class="card-images">
          <img
            class="back-image"
            src="https://www.bigbasket.com/media/uploads/p/xxl/40164735_1-anand-sweets-motichoor-laddoo.jpg"
            style="width: 100%"
          />
          <img
            class="front-image"
            src="https://m.media-amazon.com/images/I/71ejqN0YKEL._SL1500_.jpg"
            style="width: 100%"
          />
        </div>
        <h1 class="card-item">Laddoo Gift Box</h1>
        <p class="card-price">$500</p>
        <button class="card-button">
          <i class="bx bx-cart"></i>Add to Cart
        </button>
      </div>
    </div>
  </div>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@100&display=swap");
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 25vh;
  margin: 5vh auto;
  text-align: center;
  font-family: arial;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: rgba;
}
.card-item {
  /* border-bottom: 1px solid rgb(189, 186, 186);  */
  /* border-top: 1px solid rgb(189, 186, 186); */
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  margin: auto;
}
.card-price {
  color: grey;
  font-size: 2vh;
  margin: auto;
}
.card button {
  margin: auto;
  display: flex;
  align-items: center;
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #f2672c;
  text-align: center;
  cursor: pointer;
  width: 60%;
  font-size: 15px;
  border-radius: 8px;
  transform: translateY(20px);
}
.card button:hover {
  background-color: #bb3902;
}
.card-images {
  display: flex;
  overflow: hidden;
}
.card-images img {
  width: 100%;
  left: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  object-fit: contain;
}
.card-images img.front-image {
  transform: translateX(-100%);
}

.card-images img.front-image {
  opacity: 1;
}

.card-images img.back-image {
  opacity: 1;
}

.card-images img.front-image:hover {
  opacity: 0;
  cursor: pointer;
}
.card-images img.back-image:hover {
  opacity: 1;
  cursor: pointer;
}
i {
  font-size: 18px;
  align-items: center;
  margin-right: 10px;
}
</style>
