<script>
import ProductCard from "./ProductCard.vue";
export default {
  name: "BestSellerView",
  // eslint-disable-next-line vue/no-unused-components
  components: { ProductCard },
};
</script>
<template>
  <section>
    <!-- <div class="Heading"><b>Our BestSellers</b></div>
    <ProductCard v-for="i in 6" :key="i" /> -->
  </section>
  <section class="wrapper">
    <div class="container">
      <div id="Vision_heading"><h1>Our Vision</h1></div>
      <div id="vision_body">
        With the wishes of the almighty, all of us are made fortunate. Blessed
        with a Special son, an affinity for helping people, a wealth of
        knowledge in medical sciences and nutrition. We embarked on a journey to
        provide us Indians, talented in making sweets with a healthier, tastier
        and easier way of making our lives dive into the ocean of health. Join
        us on a journey filled with flavours, tastes, sweets, snacks, tradition
        and health to transform lives all across India and spread the message to
        the world.
      </div>
    </div>
  </section>
</template>
<style scoped>
.Heading {
  text-align: center;
  padding: 10px;
  font-size: larger;
  background-color: rgba(255, 102, 0, 0.385);
}
#bestsellers {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
/*parallax starts here*/
.wrapper {
  background: url("../../public/images/almonds.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}
.wrapper .container {
  display: none;
  width: 30%;
  height: fit-content;
  margin-left: 20px;
  color: rgb(91, 83, 83);
  background-color: rgba(237, 231, 225, 0.673);
  border-radius: 20px;
  box-shadow: 5px 5px 5px rgba(255, 0, 0, 0.796);
}
.wrapper .container #Vision_heading {
  text-align: center;
  color: yellow;
  padding: 10px;
}
.wrapper .container #vision_body {
  text-align: justify;
  padding: 10px;
  color: brown;
}
@media screen and (max-width: 800px) {
  .wrapper {
    height: 80vh;
  }
  .wrapper .container {
    height: max-content;
    width: 80%;
    margin: auto;
  }
}
</style>
