<script>
export default {
  // eslint-disable-next-line prettier/prettier
  name: "NavBar2",
  methods: {
    menuClick() {
      const hamburger = document.querySelector(".hamburger");
      const navLinks = document.querySelector(".nav-links");
      const links = document.querySelectorAll(".nav-links li");
      navLinks.classList.toggle("open");
      links.forEach((link) => {
        link.classList.toggle("fade");
      });
      hamburger.classList.toggle("toggle");
      window.scrollTo(0, 0);
    },
  },
};
</script>
<template>
  <div class="navbar">
    <nav>
      <div class="hamburger" ref="hamburger" @click="menuClick">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>

      <ul class="nav-links" ref="navLinks">
        <li>
          <router-link to="/" @click="menuClick">Home</router-link>
        </li>
        <li>
          <router-link to="/store" @click="menuClick">Store</router-link>
        </li>
        <!-- <div class="logo_website" @click="this.$router.push('/')">
          <img src="logo.svg" alt="Logo Image" />
          <h1>Ocean Of Health</h1>
        </div> -->
        <li>
          <router-link to="/recepies" @click="menuClick">Recepies</router-link>
        </li>
        <li>
          <router-link to="/about" @click="menuClick">About Us</router-link>
        </li>
        <li>
          <router-link to="/contact" @click="menuClick">Talk To Us</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap");

.logo_website:hover {
  cursor: pointer;
}
.logo_website h1 {
  font-size: 1.5rem;
}
nav li a {
  text-decoration: none;
  color: white;
}

nav {
  position: sticky;
  height: 4rem;
  width: 100vw;
  margin: auto;
  padding: 0%;
  top: 0;
  display: flex;
  z-index: 10;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  background: rgba(107, 104, 104, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

nav li a.router-link-exact-active {
  color: orangered;
}

/*Styling logo*/
.logo {
  padding: 1vh 1vw;
  font-size: 3rem;
  text-align: center;
}

.logo_mobile {
  padding: 1vh 1vw;
  text-align: center;
  display: none;
}

.logo img {
  height: 5rem;
  width: 5rem;
}

h1 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2.5rem;
}

h3 {
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: space-evenly;
}

.nav-grid {
  display: grid;
  width: 80vw;
  margin: auto;
  grid-template-columns: 10% 80% 10%;
}

/*Styling Links*/
.nav-links {
  display: flex;
  list-style: none;
  width: 60vw;
  margin: auto;
  padding: 0 0.7vw;
  font-size: 20px;
  text-transform: uppercase;
  align-items: center;
  align-content: flex-start;
  justify-content: space-evenly;
}

/*Styling Hamburger Icon*/
.hamburger div {
  width: 30px;
  height: 3px;
  background: #000;
  margin: 5px;
  transition: all 0.3s ease;
}

.hamburger {
  display: none;
}

/*Stying for small screens*/
@media screen and (max-width: 800px) {
  nav {
    position: fixed;
    background: none;
    z-index: 3;
    height: 4rem;
  }

  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
  }

  .nav-grid {
    width: 90vw;
  }

  h3 {
    order: 15;
  }

  .logo_mobile {
    display: block;
  }

  .logo_website {
    display: none;
  }

  .nav-links {
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    height: 100vh;
    width: 100%;
    padding: 0%;
    margin: auto;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 1s ease-out;
    justify-content: start;
    pointer-events: none;
  }

  .nav-links.open {
    clip-path: circle(1000px at 100%);
    -webkit-clip-path: circle(1000px at 100%);
    pointer-events: all;
  }

  .nav-links li {
    opacity: 0;
    margin: 5vh;
  }

  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
    margin-top: 15vh;
  }

  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }

  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }

  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.7s;
  }

  .nav-links li:nth-child(5) {
    transition: all 0.5s ease 0.8s;
  }

  .nav-links li:nth-child(6) {
    transition: all 0.5s ease 0.9s;
    margin: 0;
  }

  .nav-links li:nth-child(7) {
    transition: all 0.5s ease 1s;
    margin: 0;
  }

  li.fade {
    opacity: 1;
  }
}

/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
</style>
