<template>
  <div class="container">
    <video id="vid" class="video" loop autoplay>
      <source
        type="video/mp4"
        src="../assets/pexels-taryn-elliott-5309381.mp4"
      />
    </video>
    <div class="top-right">
      <img
        src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fsmedia2.intoday.in%2Faajtak%2Fimages%2Fstories%2F062015%2Faiims-logo_650_060215035250.jpg&f=1&nofb=1"
        alt=""
      />
    </div>
    <div class="top-left">
      <h1>
        Top Quality Products <br />
        <span>Researched At AIIMS</span>
      </h1>
      <button class="shopNow"><a>Shop Now </a></button>
    </div>
  </div>

  <CategoryComponent />
  <PackItSnackIt />
  <ProductIngredients />
  <Socials />
  <Bestsellers />
  <OurStorySection />
</template>
<script>
// Import Swiper Vue.js components

// import required modules
import { Pagination, Autoplay } from "swiper";
import CategoryComponent from "@/components/CategoryComponent.vue";
import Bestsellers from "@/components/Bestsellers.vue";
import PackItSnackIt from "@/components/PackItSnackIt.vue";
import OurStorySection from "../components/OurStorySection.vue";
import ProductIngredients from "@/components/Ingredients.vue";
import Socials from "../components/Socials.vue";
export default {
  components: {
    Bestsellers,
    PackItSnackIt,
    OurStorySection,
    CategoryComponent,
    ProductIngredients,
    Socials,
  },
  mounted: function () {
    document.getElementById("vid").play();
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
};
</script>
<style scoped>
.top-right {
  position: absolute;
  top: 3%;
  right: 3%;
}
.top-right img {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  object-fit: cover;
}
.container {
  position: relative;
  text-align: center;
  color: white;
}
video {
  min-width: 100vw;
  height: auto;
}

.top-left {
  text-align: left;
  position: absolute;
  top: 50%;
  left: 16px;
  border-radius: 15px;
}
.top-left span {
  color: rgb(1, 6, 93);

  font-size: 5rem;
  font-weight: 900;
}
.top-left h1 {
  font-size: 2em;
  text-transform: uppercase;
}

.top-left button {
  padding: 1% 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shopNow {
  border-radius: 30px;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 15px 30px;
  text-decoration: none;
  background: orangered;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

@media (max-width: 729px) {
  .top-left {
    display: block;
    margin: 22rem auto;
    text-align: center;
  }
  .top-left button {
    display: none;
  }
  .container {
    color: black;
  }
  .top-right {
    display: block;
    top: 105%;
    left: 30%;
    margin: 1rem auto;
  }
  .top-right img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  .top-left h1 {
    font-size: 1rem;
  }
  .top-left h1 span {
    font-size: 2rem;
  }
  video {
    padding-top: 10vh;
    min-width: 100vw;
  }

  .top-left h1 {
    font-size: 2em;
    text-transform: uppercase;
  }
}
</style>
