<template>
  <section class="catigory">
    <h1>Our Products</h1>
    <section class="laddoosection ScrollSection">
      <ProductCard2
        Description="Dry Fruits Laddoo"
        Ingredients="Almond, Cashew, Walnut, Raisins, Magaj, Coconut, Flaxseeds, Dates, Desi Ghee"
        Image1="https://static.toiimg.com/photo/60377196.cms"
        Image2="https://images-eu.ssl-images-amazon.com/images/I/51mkT4v%2BwZL._SX500,400_.jpg"
        Price="235"
        Fake="250"
        Discount="10"
      />
      <ProductCard2
        Description="Stuffed Dates"
        Ingredients="Medjool dates,
      chocolate, hazelnut"
        Image1="https://feelgoodfoodie.net/wp-content/uploads/2021/04/stuffed-dates-8.jpg"
        Image2="https://www.wisdomindustriesltd.com/wp-content/uploads/2020/03/aseel-premium-dates-img1.jpg"
        Price="235"
        Fake="250"
        Discount="10"
      />
      <ProductCard2
        Description="Protein Dates"
        Ingredients="Peanuts, peanut butter, honey and dates"
        Image1="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/20200107-bacon-wrapped-dates-delish-ehg-8965-jpg-1580412455.jpg"
        Image2="https://www.wisdomindustriesltd.com/wp-content/uploads/2020/03/aseel-premium-dates-img1.jpg"
        Price="235"
        Fake="250"
        Discount="10"
      />
      <ProductCard2
        Description="Super food mix"
        Ingredients="Almonds, cashew, walnuts, pumpkin seeds, sunflower seeds, Flaxseeds, cranberry, butter, Mint masala "
        Image1="https://i0.wp.com/www.ghezaeshiriin.com/wp-content/uploads/2019/04/42AC46B3-27F6-4E7E-96AD-1CBFF4FAD2D0.jpeg"
        Image2="https://images-platform.99static.com//0WAEVRFsOu3Z6U7BN4dfWMNdLDs=/299x401:1704x1806/fit-in/500x500/99designs-contests-attachments/71/71508/attachment_71508240"
        Price="235"
        Fake="250"
        Discount="10"
      />
    </section>
  </section>
</template>
<script>
// Import Swiper Vue.js components
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Autoplay } from "swiper";
import ProductCard2 from "./ProductCard2.vue";

export default {
  name: "ProductIngredients",
  components: { ProductCard2 },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
};
</script>
<style scoped>
.holder {
  text-align: center;
  align-content: center;
}
.laddoosection {
  grid-template-columns: repeat(4, 1fr);
}
.datessection {
  grid-template-columns: repeat(5, 1fr);
}
.foodmixsection {
  grid-template-columns: repeat(1, 1fr);
}
section {
  display: grid;
  width: 80vw;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-radius: 15px;
}
img {
  width: 100%;
  height: fit-content;
  margin: auto;
}
h1 {
  margin-top: 2rem;
  margin-left: 3.5rem;
  text-align: left;
  font-size: 3rem;
}

.ScrollSection {
  width: 90vw;
  margin: auto;
  height: max-content;
  padding: 2rem;
  padding-top: 0.5rem;
  overflow-x: scroll;
  display: grid;
  grid-auto-flow: column;
  gap: 3rem;
  overscroll-behavior-inline: contain;
  scroll-snap-type: x mandatory;
  scroll-padding-inline: 1rem;
  font-family: sans-serif;
}
@media (max-width: 1300px) {
  section {
    grid-template-columns: repeat(3, 1fr);
    background-color: antiquewhite;
    width: 90vw;
    gap: 2rem;
  }
}
@media (max-width: 900px) {
  section {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 729px) {
  section {
    grid-template-columns: repeat(1, 1fr);
  }
  .holder {
    margin-top: 5rem;
  }
  .holder h1 {
    font-size: 2.3rem;
    text-align: left;
    margin-top: 2rem;
  }
}
.catigory {
  width: 90vw;
  margin: 10vh auto;
  text-align: center;
}

h1 {
  font-size: 2rem;
}
.swiperBlock {
  display: block;
  position: relative;
  top: 0%;
  width: 80vw;
  height: 20vh;
  margin-bottom: 2rem;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.card {
  margin: 30px auto;
  width: 300px;
  height: 250px;
  border-radius: 20px;
  display: grid;
  cursor: pointer;
  transition: 0.4s;
}

.card .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card .card_image img {
  width: inherit;
  height: inherit;
  border-radius: 20px;
  object-fit: cover;
}

.card .card_title {
  text-align: center;
  border-radius: 0px 0px 20px 20px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-top: -80px;
  height: 40px;
}

.card:hover {
  transform: scale(1.05, 1.05);
}

@media (max-width: 768px) {
  .card {
    width: 225px;
    height: 175px;
  }
}

.title-white {
  color: white;
}
</style>
