<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

library.add(faTwitter, faInstagram, faFacebook);

export default {
  // eslint-disable-next-line prettier/prettier
  name: "FooterComp",
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<template>
  <footer>
    <div class="footer-div">
      <!-- Section 1 -->

      <div class="sectionOne">
        <h1>Socials</h1>
        <h3>Follow Us</h3>
        <li class="icon">
          <i class="bx bxl-instagram"></i>
        </li>
        <li class="icon">
          <font-awesome-icon icon="fa-brands fa-twitter" />
        </li>
        <li class="icon">
          <font-awesome-icon icon="fa-brands fa-twitter" />
        </li>
      </div>
      <!-- Section 2 -->

      <div class="sectionTwo">
        <h1>Nav Links</h1>
        <div class="navLinks">
          <div @click="scrollTop">
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About Us</router-link></li>
            <li><router-link to="/store">Store</router-link></li>
            <li><router-link to="/recepies">Recipes</router-link></li>
          </div>
        </div>
      </div>

      <!-- Section 3 -->

      <div class="sectionThree">
        <h1>Our Credentials</h1>
        <p>FSSAI No.: 23322011003192</p>
      </div>
      <!-- Section 5 -->
      <div class="sectionTwo">
        <h1>Contact Us</h1>
        <div class="navLinks">
          <div>
            <li><a>Oceanofhealth@gmail.com</a></li>
            <li><a href="tel:9354210465">+91 9354210465</a></li>
            <li><a href="tel:9818778755">+91 9818778755</a></li>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&family=Poppins:wght@200&display=swap");
* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  list-style: none;
  line-height: 30px;
}

footer {
  margin-top: 5rem;
  font-family: "Montserrat", sans-serif;
  color: #000;
  width: 100vw;
  background: rgba(255, 165, 0, 0.75);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.footer-div {
  margin: 7% auto;
  display: grid;
  width: 90%;
  grid-template-columns: repeat(5, 1fr);
  height: 80%;
  align-items: baseline;
  justify-items: end;
}

.footer-div .sectionOne {
  width: 70%;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
}

h1,
h3,
li {
  margin: 5% auto;
}

.icon {
  display: inline;
}
.fab {
  margin-right: 5%;
}

.footer-div .sectionTwo {
  width: 90%;
}
.navLinks {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(1, 1fr);
}

.footer-div.sectionThree {
  width: 80%;
}

.input-form {
  margin: 5% auto;
}

.form {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 80%;
}

.input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  letter-spacing: 0.4px;

  padding: 0 150px 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

a {
  background-image: linear-gradient(to right, #fff, #fff 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

a:before {
  content: "";
  background: #fff;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

a:hover {
  cursor: pointer;
  background-position: 0;
}

a:hover::before {
  width: 100%;
}
button {
  background-color: transparent;
  border: none;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}

.button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

@media (max-width: 1250px) {
  .footer-div {
    width: 90%;
    grid-template-columns: repeat(3, 1fr);
    height: auto;
  }

  h1,
  h3,
  p,
  .fab,
  .navLinks,
  input {
    margin: 3%;
  }

  li {
    margin: 10px;
  }

  .sectionOne,
  .sectionTwo,
  .sectionThree {
    width: 90%;
  }
}

@media (max-width: 950px) {
  .footer-div {
    width: 90%;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    justify-items: start;
  }
}

@media (max-width: 600px) {
  h1,
  h3,
  p,
  .fab,
  .navLinks,
  input {
    margin: 6%;
  }

  li {
    margin: 10px;
  }

  .sectionOne,
  .sectionTwo,
  .sectionThree {
    width: 100%;
  }

  .footer-div {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
  }
}
</style>
