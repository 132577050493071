<template>
  <section>
    <div class="left">
      <div class="img">
        <!-- <img src="../../public/images/story-2.jpg" alt="" /> -->
      </div>
      <div class="heading"><h1>We pack it,you Snack it</h1></div>
      <div class="para">
        <p>
          With the richness of
          <span class="bg">
            Almonds, Pumpkin Seeds, Sunflower Seeds, Flax Seeds, Cashews,
            Walnuts, Cranberry, and a hand-blended Spice Mix </span
          >. We create our products with motherly love, utmost care, and our own
          hands.<br /><br />
          We create every snack for our customers with the blessings of the
          Supreme power, thus, we end up creating new standards each time. Our
          motto has always been the same, We pack it, and You snack it.
        </p>
      </div>
    </div>
    <div class="right">
      <img src="../../public/images/story-1.jpg" alt="" />
    </div>
  </section>
</template>
<style scoped>
section {
  width: 90vw;
  margin: 2rem auto;
  height: max(50vh, max-content);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background-color: #f2ddcc;
  border-radius: 15px;
}
section .left {
  height: 100%;
  flex: 8;
  margin-right: 1rem;
}
@media screen and (max-width: 900px) {
  section {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1.25rem;
  }
}
section .left .img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
section .left .heading h1 {
  margin: 1rem 0;
  text-align: left;
  font-size: 3rem;
}
.bg {
  color: rgb(255, 93, 53);
  font-weight: 700;
}
section .left .para p {
  line-height: 2rem;
  text-align: left;
}
section .right {
  margin: auto;
  height: 100%;
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}
section .right img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
}
@media screen and (max-width: 820px) {
  section {
    flex-direction: column;
  }
  section .right {
    margin-top: 4rem;
  }
  section .left .heading h1 {
    font-size: 1.8rem;
  }
}
</style>
